@import "./settings";
@import "./bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Text&family=Nunito+Sans:ital,opsz,wght@0,6..12,400;0,6..12,700;1,6..12,400;1,6..12,700&display=swap');

* {
  font-family: 'Nunito Sans', sans-serif;
}

h1,
.h1 {
  color: $secondary;
  font-family: 'Libre Caslon Text', serif;
}

h2,
.h2 {
  font-family: 'Libre Caslon Text', serif;
  color: $primary;
}
a.active {
  background-color: darken($primary, 10);
}

table {
  width: 100%;

  tr {
    border-bottom: 1px solid $gray-300;

    &:last-child {
      border: none;
    }

    td {
      padding: 4px;
    }
  }
}
.header-image {
  width: 100%;
}

.menu-trigger {
  height: 30px;
  width: 30px;
  border: none;
  appearance: none;
  background: url("../menu.svg") no-repeat center center;
  background-size: 30px 30px;
  color: white;

  &.active {
    background-image: url("../close.svg");
  };
}
h2, h3, h4 ,h5 {
  margin-bottom: .75em;
}
p {
  margin-bottom: 1.5em;
}
